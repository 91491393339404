import React from 'react'
import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import PhotoGrid from '../components/PhotoGrid'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import { FaInstagram } from 'react-icons/fa'

class IndexPageTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.state =
      { emailSubmitted: '',
        emailResponseLabel: ''
      }

    this.handleSubmitEmail = this.handleSubmitEmail.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  async handleSubmitEmail (e) {
    e.preventDefault()
    const result = await addToMailchimp(this.state.emailSubmitted)
    this.setState({ emailResponseLabel: result.msg })
  }

  handleEmailChange (event) {
    this.setState({ emailSubmitted: event.target.value })
  }

  render () {
    const { data } = this.props
    const { markdownRemark: post } = data
    return (
      <Layout>
        <section className="section is-paddingless-top">
          <div className="container">
            <div className="columns">
              <div className="column is-8 is-offset-2 has-text-centered">
                <h1 className="is-size-1 is-size-2-mobile">
                  {post.frontmatter.title}
                </h1>
                <h1 className="is-size-2 is-size-3-mobile">
                  {post.frontmatter.subtitle}
                </h1>
                <h2 className="is-size-3 is-size-4-mobile is-body-text is-italic">
                  {post.frontmatter.description}
                </h2>
                <div>
                  <PhotoGrid gridItems={post.frontmatter.instagramImages.map(image => image.image.childImageSharp)} /> 
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    ) 
  }
}

IndexPageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPageTemplate

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
        frontmatter {
            title
            subtitle
            description
            subscribeText
            instagramImages {
              image {
                ...SquarePhoto
              }
            }
      }
    }
  }
`
